import './styles/reset.css';
import { Stack } from '@mui/material';
import { OIDCAuthIFrame } from '@proapteka/oidc-auth-iframe';
import { lazy, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import AppRouterNotProtected from '@/app/routes/AppRouterNotProtected';
import { authController } from '@/shared/axios/requester';
import { Loader } from '@/shared/ui/Loader/Loader';

import 'react-toastify/dist/ReactToastify.css';

const AppRouter = lazy(() => import('./routes/AppRouter'));

function App() {
  const [ showChangePasswordModal, setShowChangePasswordModal ] = useState(true);
  const LoaderFullScreen = (
    <Stack width={'100%'} height={'100vh'} justifyContent={'center'} alignItems={'center'}>
      <Loader/>
    </Stack>
  );

  return (
    <>
      <AppRouterNotProtected setShowChangePasswordModal={setShowChangePasswordModal} />
      {showChangePasswordModal &&<OIDCAuthIFrame authControllerInstance={authController} preloader={LoaderFullScreen}>
        <AppRouter />
      </OIDCAuthIFrame>
      }
      <ToastContainer />
    </>
  );
}

export default App;
