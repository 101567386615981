import { Route, Routes } from 'react-router-dom';

import ChangePasswordModal from '@/pages/ChangePassword/ChangePasswordModal';
import UserMailConfirm from '@/pages/UserMailConfirm/UserMailConfirm';


const AppRouterNotProtected = ({ setShowChangePasswordModal }) => {
  return (
    <Routes>
      <Route path="/email-password-reset" element={<ChangePasswordModal setShowChangePasswordModal={setShowChangePasswordModal}/>} />
      <Route path="/email-confirm" element={<UserMailConfirm setShowChangePasswordModal={setShowChangePasswordModal}/>} />
    </Routes>
  );
};

export default AppRouterNotProtected;
