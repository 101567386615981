import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import queryString from 'query-string';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { passportUrl, profileUrl } from '@/app/env/env.config';


const UserMailConfirm = ({ setShowChangePasswordModal }) => {
  const [ mailConfirm, setMailConfirm ] = useState(false);
  const [ isNewLinkSend, setIsNewLinkSend ] = useState(false);
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(false);
  const navigate = useNavigate();

  const userId = queryString.parse(
    window.location.search.substring(1)
  ).userId as string;

  const navigateToProfile = () => {
    window.location.href = passportUrl;
  };

  const sendNewLink = async () => {
    setIsButtonDisabled(true);
    await axios.post('user/email/confirm/direction', { id: userId, }, {
      withCredentials: true,
      baseURL: profileUrl
    }).then((_) => {
      setIsNewLinkSend(true);
    }).catch((error) => {
      console.error('Произошла ошибка при настройке запроса:', error.message);
    });
  };

  useLayoutEffect(() => {
    setShowChangePasswordModal(false);
    const { code, userId } = queryString.parse(
      window.location.search.substring(1)
    );
    axios.post('user/email/confirm', {
      id: userId,
      code: code
    }, {
      withCredentials: true,
      baseURL: profileUrl
    }).then((_) => {
      setMailConfirm(true);
    }).catch((_) => {
      setMailConfirm(false);
    });

    return () => setShowChangePasswordModal(true);
  }, []);

  return (
    <Container maxWidth={'xl'}>
      <Stack gap={2}>
        {
          mailConfirm ?
            <Typography
              textAlign={'center'}
              color={'success'}
              variant={'h6'}>
              Почта пользователя подтверждена
            </Typography> :
            <Typography
              textAlign={'center'}
              color={'error'}
              variant={'h6'}>
              {isNewLinkSend ? 'Ссылка для подтверждения пароля отправлена на почту': 'Почта пользователя не подтверждена'}
            </Typography>
        }

        <Button
          variant="outlined"
          onClick={mailConfirm ? navigateToProfile : sendNewLink}
          disabled={isButtonDisabled}
        >{mailConfirm ? 'Авторизоваться' : 'Запросить новую ссылку для смены пароля'}</Button>
      </Stack>
    </Container>
  );
};

export default UserMailConfirm;
