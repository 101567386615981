import { GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';


import { ThemeContext } from '@/shared/helpers/context/ThemeContext';
import { getThemeByName } from '@/shared/helpers/theme';


interface ThemeProps {
    children?: React.ReactChild | React.ReactNode
}

const ThemeInjector = ({ children }: ThemeProps) => {
  const curThemeName = localStorage.getItem('appTheme') || 'mainLightTheme';
  const [ themeName, _setThemeName ] = React.useState(curThemeName);
  const theme = getThemeByName(themeName);

  const setThemeName = (themeName: string): void => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    <ThemeContext.Provider value={setThemeName}>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={({ palette }) => ({ body: { backgroundColor: palette.background.default } })}
        />
        {children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
export default ThemeInjector;
